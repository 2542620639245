import React from "react";
import NavLink from "components/NavLink";
import ArrowRight from "assets/images/svg/arrow-right.svg";
import { connect } from "react-redux";
import { selectPlatformSourceFromApp } from "store/app/selectors";
import { createStructuredSelector } from "reselect";

const InfoBlock = ({
    title = "",
    paragraphs,
    color = "#fff",
    linkText = "",
    linkHref = "",
    className = "",
    onTabClick,
    isApp,
    isWebFlowLink,
    onResetFilter = () => {},
    isLinkResetFilter = false,
}) => {
    return (
        <div className={`flex column info-block ${className}`} style={{ background: color }}>
            <span className="text-16 fw-500 mb-3">{title}</span>
            <div className="flex column info-items">
                {paragraphs.map((paragraph) => (
                    <div
                        key={paragraph}
                        className="info-item fw-700 text-22"
                        onClick={onTabClick}
                        onKeyPress={onTabClick}
                        role="button"
                        tabIndex="0"
                    >
                        {paragraph}
                    </div>
                ))}
            </div>

            {linkText && (
                <div className="flex align-center row learn-more">
                    <span style={{ cursor: "pointer" }} className="flex align-center">
                        {isLinkResetFilter ? (
                            <span className="text-14 fw-600" onClick={onResetFilter}>
                                {linkText}
                            </span>
                        ) : (
                            <NavLink isForcePushed={isWebFlowLink} className="text-14 fw-600" href={linkHref} isExternal={isApp}>
                                {linkText}
                            </NavLink>
                        )}
                        <ArrowRight />
                    </span>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    isApp: selectPlatformSourceFromApp,
});

export default connect(mapStateToProps)(InfoBlock);
