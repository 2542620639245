import React, { Fragment, useState, useEffect } from "react";
import { useFormattedMessage } from "hooks";
import Cookies from "js-cookie";
import { withFormik } from "formik";
import { useRouter } from "next/router";
import Checkbox from "components/Checkbox";

const FINX_COOKIE = require("common/constants");

const messages = {
    productSubscribedThankyou: "product_subscribed_thankyou",
    registerToGetAccess: "register_to_get_access",
    dontShowWindow: "dont_show_window",
    registerNow: "register_now",
    shutDown: "close_btn",
};

const SubscribedProduct = ({ closeModal, productName, isLoggedIn }) => {
    const { formatMessage } = useFormattedMessage();
    const [fieldValue, setFieldValue] = useState(false);
    const router = useRouter();

    useEffect(() => {
        isLoggedIn && Cookies.set(FINX_COOKIE.DONT_SHOW_SUBSCRIBE_WINDOW, fieldValue);
    }, [fieldValue]);

    return (
        <div className="content-part">
            <div className="product-subscribed">
                <div className="icon-section">
                    <i className="far fa-bell"> </i>
                </div>
                <h4 className="heading mb-8">
                    {formatMessage(messages.productSubscribedThankyou)} {productName}.
                </h4>

                {isLoggedIn ? (
                    <Fragment>
                        <Checkbox
                            name="dontshowwindow"
                            inputId={formatMessage(messages.dontShowWindow)}
                            label={formatMessage(messages.dontShowWindow)}
                            value={fieldValue}
                            onChange={() => setFieldValue(!fieldValue)}
                        />
                        <button className={"mw-100 my-2 "} onClick={() => closeModal()}>
                            {formatMessage(messages.shutDown)}
                        </button>
                    </Fragment>
                ) : (
                    <Fragment>
                        <p className="my-5">{formatMessage(messages.registerToGetAccess)}</p>
                        <button
                            className={"mw-100 my-2 "}
                            onClick={() => {
                                closeModal();
                                router.push("/register");
                            }}
                        >
                            {formatMessage(messages.registerNow)}
                        </button>
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default withFormik({})(SubscribedProduct);
