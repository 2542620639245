import React from "react";
import { useFormattedMessage } from "hooks";
import SideModal from "components/SideModal";
import SubscribeProduct from "./SubscribeProduct";
import UnsubscribedProduct from "./UnsubscribedProduct";
import SubscribedProduct from "./SubscribedProduct";

const messages = {
    productSubscribedTitle: "product_subscribed_title",
};
const WrappedProductSubscription = ({
    modalOpen,
    closeModal,
    productName,
    isLoggedIn,
    setSubscribed,
    projectID,
    subscriptionStep,
    setSubscriptionStep,
}) => {
    const { formatMessage } = useFormattedMessage();

    const SubscribedProductStep = (subscriptionStep) => {
        switch (subscriptionStep) {
            case "unsubscribed":
                return (
                    <UnsubscribedProduct
                        closeModal={closeModal}
                        productName={productName}
                        isLoggedIn={isLoggedIn}
                        setSubscribed={setSubscribed}
                        projectID={projectID}
                    />
                );
            case "subscribed":
                return (
                    <SubscribedProduct
                        closeModal={closeModal}
                        productName={productName}
                        isLoggedIn={isLoggedIn}
                        setSubscribed={setSubscribed}
                        projectID={projectID}
                    />
                );

            case "subscribe":
            default:
                return (
                    <SubscribeProduct
                        productName={productName}
                        isLoggedIn={isLoggedIn}
                        projectID={projectID}
                        setSubscribed={setSubscribed}
                        setSubscriptionStep={setSubscriptionStep}
                    />
                );
        }
    };

    return (
        <SideModal open={modalOpen} onCloseButtonClick={closeModal} heading={formatMessage(messages.productSubscribedTitle)}>
            {SubscribedProductStep(subscriptionStep)}
        </SideModal>
    );
};

export default WrappedProductSubscription;
