import React, { Fragment, useState, useEffect } from "react";
import { useFormattedMessage } from "hooks";
import { withFormik } from "formik";
import Cookies from "js-cookie";
import NextButton from "components/NextButton";
import Checkbox from "components/Checkbox";

const FINX_COOKIE = require("common/constants");
const messages = {
    productUnsubscribeThankyouFirst: "product_unsubscribed_thankyou_first",
    productUnsubscribeThankyouSecond: "product_unsubscribed_thankyou_second",
    dontShowWindow: "dont_show_window",
    shutDown: "close_btn",
};

const UnsubscribedProduct = ({ closeModal, productName, isLoggedIn }) => {
    const { formatMessage } = useFormattedMessage();
    const [fieldValue, setFieldValue] = useState(false);

    useEffect(() => {
        isLoggedIn && Cookies.set(FINX_COOKIE.DONT_SHOW_SUBSCRIBE_WINDOW, fieldValue);
    }, [fieldValue]);

    return (
        <div className="content-part">
            <div className="product-subscribed">
                <div className="icon-section">
                    <i className="far fa-bell" />
                </div>
                <h4 className="heading mb-8">
                    {formatMessage(messages.productUnsubscribeThankyouFirst)} {productName}{" "}
                    {formatMessage(messages.productUnsubscribeThankyouSecond)}
                </h4>

                {isLoggedIn ? (
                    <Fragment>
                        <Checkbox
                            name="dontshowwindow"
                            inputId={formatMessage(messages.dontShowWindow)}
                            label={formatMessage(messages.dontShowWindow)}
                            value={fieldValue}
                            onChange={() => setFieldValue(!fieldValue)}
                        />
                        <NextButton
                            className={"mw-100 my-2 "}
                            type="button"
                            title={formatMessage(messages.shutDown)}
                            onClick={() => {
                                closeModal();
                            }}
                        />
                    </Fragment>
                ) : (
                    <Fragment>
                        <NextButton
                            className={"mw-100 my-2 "}
                            type="button"
                            title={formatMessage(messages.shutDown)}
                            onClick={() => {
                                closeModal();
                            }}
                        />
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default withFormik({})(UnsubscribedProduct);
